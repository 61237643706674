import type { FC } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { RoleEnum } from '@rivallapp/volosports-components';

import { useAuthStore } from '../../../../zustand-stores';
import Picture from '../../../../components/Picture/Picture';
import styles from '../../../../styles/main.module.scss';

const cx = classNames.bind(styles);

/* Assets */
const EmailIconSrc = 'https://rivall-public.s3-us-west-1.amazonaws.com/home-page/email.png';

const TopBar: FC = () => {
  const roles = useAuthStore(store => store.roles);
  const isAuthenticated = useAuthStore(store => store.isAuthenticated);

  const isStaff = roles.includes(RoleEnum.STAFF);
  const isAdmin = roles.includes(RoleEnum.ADMIN) || roles.includes(RoleEnum.OWNER);

  return (
    <div className={cx('topBar')}>
      <div className="container">
        <div className="row justify-content-end">
          {/* Left Side */}
          <div className="col-auto">
            <Picture src={EmailIconSrc} alt="Icon of a mail envelope" />
            <Link className={cx('contactButtons', 'd-none', 'd-md-inline')} to="/contact-us">
              Contact Us
            </Link>
          </div>

          {/* Divider */}
          <div className="col-auto">
            <span className={cx('line')} />
          </div>

          {/* Right Side */}
          {!isAuthenticated && (
            <div className="col-auto">
              <Link className={cx('actionButtons', 'login')} to="/login">
                Login
              </Link>
            </div>
          )}

          {isAuthenticated && (
            <div className="col-auto">
              <Link className={cx('actionButtons', 'login')} to="/app/dashboard">
                {isAdmin || isStaff ? 'Player Dashboard' : 'Dashboard'}
              </Link>
            </div>
          )}

          {isAuthenticated && isAdmin && (
            <div className="col-auto">
              <Link className={cx('actionButtons')} to="/rfo/leagues">
                Admin Dashboard
              </Link>
            </div>
          )}
          {isAuthenticated && isStaff && (
            <div className="col-auto">
              <Link className={cx('actionButtons')} to="/staff">
                Staff Portal
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
