import type { AxiosResponse } from 'axios';
import type { RoleEnum } from '@rivallapp/volosports-components';
import { request } from '../request';

export type LoginResponse = {
  roles: RoleEnum[];
  user_id: string;
  isTest: boolean;
  staff_of: string[];
  user: {
    _id: string;
    email: string;
    username: string;
    roles: string[];
  };
  authObj: {
    token: string;
    expires: string;
  };
};

type GetLoggedInUserResponse = {
  user_id: string;
  roles: RoleEnum[];
  email: string;
  username: string;
};

export interface SessionApi {
  logIn: (args: {
    email?: string;
    phone?: string;
    password: string;
  }) => Promise<AxiosResponse<LoginResponse>>;
  logOut: () => Promise<AxiosResponse>;
  resetPassword: (args: {
    email: string;
    token: string;
    password: string;
  }) => Promise<AxiosResponse<{ message: string }>>;
  getLoggedInUser: () => Promise<AxiosResponse<GetLoggedInUserResponse>>;
}

const sessionApi: SessionApi = {
  logIn: ({ email, phone, password }) => request.put('/sessions', { email, phone, password }),
  logOut: () => request.put('/sessions/logout'),
  resetPassword: ({ email, token, password }) =>
    request.post('/sessions/reset', { email, token, password }),
  getLoggedInUser: () => request.get('/sessions/me'),
};

export default sessionApi;
