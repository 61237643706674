import { onError } from '@apollo/client/link/error';
import sendToSentry from '../sentryErrorHandler';

const errorLogLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      const str = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
        locations
      )}, Path: ${path}`;
      console.error(str);
      sendToSentry(new Error(str));
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

export default errorLogLink;
