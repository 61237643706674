import PropTypes from 'prop-types';

const Error = ({ error, onlyMessage, hideString }) => (
  <div className="p-1 text-center" style={{ color: 'darkred' }}>
    {!hideString && 'Error!'}{' '}
    {JSON.stringify(onlyMessage ? error?.message || error : error).replaceAll('"', '')}
  </div>
);

Error.propTypes = {
  error: PropTypes.oneOfType([PropTypes.shape({ message: PropTypes.string }), PropTypes.string])
    .isRequired,
  onlyMessage: PropTypes.bool,
  hideString: PropTypes.bool,
};

Error.defaultProps = {
  onlyMessage: true,
  hideString: false,
};

export default Error;
