import { RoleEnum } from '@rivallapp/volosports-components';
import useAuthStore from '../AuthStore';

/**
 * Used after a user authenticates on the login page to get their redirect URL.
 *
 * If a redirect path was previously stored (ie. they tried to visit a protected
 * page while not logged in), we will _attempt_ to send them back to that page.
 *
 * Otherwise the user will get redirected to their dashboard, based upon their highest access level.
 */
const getLoginRedirectPath = () => {
  const { redirect } = useAuthStore.getState();

  // If we stored a redirect URL, use that.
  if (redirect) return redirect;

  // Otherwise navigate to their dashboard
  const { roles } = useAuthStore.getState();

  if (roles.includes(RoleEnum.ADMIN) || roles.includes(RoleEnum.OWNER)) {
    return '/rfo/leagues';
  }
  if (roles.includes(RoleEnum.STAFF)) {
    return '/staff';
  }
  if (roles.includes(RoleEnum.PLAYER)) {
    return '/app/dashboard';
  }

  return '/';
};

export default getLoginRedirectPath;
