import { RoleEnum } from '@rivallapp/volosports-components';
import useAuthStore from '../AuthStore';

type Role = keyof typeof RoleEnum;

/**
 * Current roles are little bit hairy, so for now
 * use this to determine who can view which pages.
 *
 * | Role     | Roles with access           |
 * | :------- | :-------------------------- |
 * | ADMIN    | ADMIN, OWNER                |
 * | OWNER    | ADMIN, OWNER                |
 * | STAFF    | ADMIN, OWNER, STAFF         |
 * | PLAYER   | ADMIN, OWNER, STAFF, PLAYER |
 * */
const hasAccess = (role: Role | string) => {
  const { roles } = useAuthStore.getState();
  switch (role) {
    case RoleEnum.ADMIN:
    case RoleEnum.OWNER:
      return roles.includes(RoleEnum.ADMIN) || roles.includes(RoleEnum.OWNER);
    case RoleEnum.STAFF:
      return (
        roles.includes(RoleEnum.ADMIN) ||
        roles.includes(RoleEnum.OWNER) ||
        roles.includes(RoleEnum.STAFF)
      );
    case RoleEnum.PLAYER:
      return (
        roles.includes(RoleEnum.ADMIN) ||
        roles.includes(RoleEnum.OWNER) ||
        roles.includes(RoleEnum.STAFF) ||
        roles.includes(RoleEnum.PLAYER)
      );
    default:
      return true;
  }
};

export default hasAccess;
