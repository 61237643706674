import { configureClient } from '@rivallapp/apollo-client';
import { useAuthStore } from '../zustand-stores';
import { hasuraURL, hasuraWsURL } from '../environment';
import errorLogLink from './links/createErrorLogLink';

if (!hasuraURL || !hasuraWsURL)
  throw new Error(
    `Missing endpoints in environment: ${!hasuraURL ? 'hasuraURL' : ''} ${!hasuraWsURL ? 'hasuraWsURL' : ''}`
  );

const hasuraClient = configureClient(
  {
    getAuthToken: () => useAuthStore.getState()?.authToken,
    getRoles: () => useAuthStore.getState()?.roles,
    graphqlURL: hasuraURL,
    wsURL: hasuraWsURL,
  },
  {
    link: errorLogLink,
  }
);

export default hasuraClient;
