import { getMainDefinition } from '@apollo/client/utilities';
import { HttpLink, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
const trimSlash = (url) => (url.endsWith('/') ? url.slice(0, -1) : url);
const createWsLink = (wsURL) => new GraphQLWsLink(createClient({
    url: `${trimSlash(wsURL)}/hapi/v1/websockets`,
}));
const createHttpLink = (graphqlURL) => new HttpLink({ uri: `${trimSlash(graphqlURL)}/hapi/v1/graphql` });
const createSplitWsHttpLink = ({ graphqlURL, wsURL }) => split(({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
}, createWsLink(wsURL), createHttpLink(graphqlURL));
export default createSplitWsHttpLink;
