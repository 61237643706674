import { ApolloError, ApolloLink } from '@apollo/client';
import { GraphQLError } from 'graphql';
const simulateErrors = (operation, forward) => {
    if (process.env.NODE_ENV !== 'development')
        return forward(operation); // safety net against production (also applied in `createLink.ts`)
    const context = operation.getContext();
    const { errorPercent: rawErrorPercent = 1, simulateApolloError, simulateNetworkError, errorMessage, extraInfo, } = context;
    const errorPercent = Math.min(Math.max(rawErrorPercent, 0), 1); // Ensure it's between 0 and 1.
    // simulates a `networkError` in the Apollo client
    if (simulateNetworkError && Math.random() < errorPercent) {
        throw new ApolloError({
            errorMessage: errorMessage || 'Simulated Network Error',
            extraInfo: extraInfo || 'This is a simulated error from the Apollo client',
            ...context,
        });
    }
    // simulates an actual `GraphQL error` from the API
    if (simulateApolloError) {
        return forward(operation).map(data => {
            return Math.random() < errorPercent
                ? {
                    data: null,
                    errors: [new GraphQLError(errorMessage || 'Simulated Apollo Error')],
                }
                : data;
        });
    }
    return forward(operation);
};
/**
 * @description
 * This function is used to simulate an error in the Apollo client.
 * **This is used for testing purposes in `__DEV__` mode only.**
 *
 * - pass the `simulateApolloError` or the `simulateNetworkError` context variable to the query or mutation to simulate an error
 * this will throw a `GraphQL` error or `Network` error to test the error handling in the client UI.
 *
 * - **if the `simulateApolloError` or the `simulateNetworkError` context variable is not passed, the request will be sent to the API as normal.**
 *
 * @example
 * ```ts
 * const { data, loading, error } = useQuery(QUERY, {
 *  errorPolicy: 'all',
 *  context: { simulateApolloError: true, errorPercent: 0.5 },
 * });
 * ```
 */
const createErrorSimulationLink = () => {
    if (process.env.NODE_ENV === 'development') {
        return new ApolloLink(simulateErrors);
    }
    return undefined; // createLink.ts will filter this out
};
export default createErrorSimulationLink;
